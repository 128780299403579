import { render, staticRenderFns } from "./Ngo-Info-Signup.html?vue&type=template&id=b6f7a0fe&scoped=true&"
import script from "./Ngo-Info-Signup.js?vue&type=script&lang=js&"
export * from "./Ngo-Info-Signup.js?vue&type=script&lang=js&"
import style0 from "./Ngo-Info-Signup.scss?vue&type=style&index=0&id=b6f7a0fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6f7a0fe",
  null
  
)

export default component.exports