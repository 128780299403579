/*eslint-disable */
const $ = window.jQuery = require('jquery');
import {
  mapGetters
} from 'vuex';
import {
  bus
} from '@/main';
import toast from '@/services/toast';
export default {
  name: 'doctor-in-mail-popup',
  components: {},
  props: ['userInfo'],
  data () {
    return {
      message:'',
      chatRequestDetails:null,
      selfUserDetails:null
    }
  },
  computed: {
  },
  mounted () {
    this.chatRequestDetails = this.userInfo
  },
  methods: {
    sendChatRequestConfirm(e) {
      this.$validator.validateAll().then((result) => {
        this.submitted = true;
        if (result) {
            let data = {
              doctor_id:this.chatRequestDetails.id,
              patient_message:this.message,
            }
            this.$store.dispatch('doctorInitialMessageFun', data)
              .then((res) => {
                  toast.success(res.data.user_msg || res.data.msg);
                  this.$store.dispatch('getDoctorsListApiFun')
                  let notificationPayload = { 
                    type:'patient_message',
                    data_id:this.chatRequestDetails.id,
                    message:this.message
                  }
                  this.$store.dispatch('postNotificationFunc', notificationPayload)
                  this.closeChatRequestConfirm()
              }).catch((error) => {
                  toast.error(error.data.user_msg || error.data.msg);
                  this.closeChatRequestConfirm()
              })
            return;
        }
      });
    },
    closeChatRequestConfirm(){
      bus.$emit('directSmsForPopupBus', false)
      document.body.classList.remove('popup_open_body')
    },
  },
  watch:{
  }
}