import { render, staticRenderFns } from "./before-booking-message-list.html?vue&type=template&id=5920c80d&scoped=true&"
import script from "./before-booking-message-list.js?vue&type=script&lang=js&"
export * from "./before-booking-message-list.js?vue&type=script&lang=js&"
import style0 from "./before-booking-message-list.scss?vue&type=style&index=0&id=5920c80d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5920c80d",
  null
  
)

export default component.exports