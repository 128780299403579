import { render, staticRenderFns } from "./global-notification-list.html?vue&type=template&id=5cc9546a&scoped=true&"
import script from "./global-notification-list.js?vue&type=script&lang=js&"
export * from "./global-notification-list.js?vue&type=script&lang=js&"
import style0 from "./global-notification-list.scss?vue&type=style&index=0&id=5cc9546a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc9546a",
  null
  
)

export default component.exports