/*eslint-disable */
import {bus} from '@/main';  
import uploadMedicalRecords from '@/components/private/health-record-main-popup';
import uploadDocumentID from '@/components/private/Upload-Document-ID';
import addAboutOfUserInfo from '@/components/private/add-about-of-user-info/';
import blurbContentInfo from '@/components/private/blurb-content-for-lymphomap-signup/';
import ngoSignUpForm from '@/components/private/Ngo-Info-Signup/';
import blurbContentNgo from '@/components/private/blurb-content-for-ngo/';
import doctorList from '@/components/private/doctor-list-for-dashboard/';
import globalNotificationList from '@/components/private/global-notification-list/';
import healthRecordViewPopup from '@/components/private/health-record-view-popup/';
import countries from '@/services/country.json'
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'dashboard-main',
  components: {
    'upload-medical-record' : uploadMedicalRecords,
    'upload-document-Id': uploadDocumentID,
    'add-about-user-info' : addAboutOfUserInfo,
    'blurb-content':blurbContentInfo,
    'ngo-signup-popup':ngoSignUpForm,
    'blurb-content-ngo' : blurbContentNgo,
    'doctor-list' : doctorList,
    'notification-list' : globalNotificationList,
    'health-record-view' : healthRecordViewPopup
  },
  props: [],
  data () {
    return {
      selfUserDetailData:{},
      updatePatientInfoData:'',
      recentAppointmentDetails:null,
      updateProfilePhotoModal:false,
      dashboardTab:'appointment',
      healthRecordData:null,
      healthRecordDataResults:[],
      uploadMedicalRecordsModal:false,
      countryList:[],
      dashboardInfoTab:'Info',
      uploadDocumentIDState:false,
      addAboutPopupState:false,
      documentIdProofData:null,
      blurTextbPopupState:false,
      lymphomapPrflInfo:null,
      lymphomapPrflInfoData:null,
      ngoSignUpFormPopup:false,
      ngoBlurbContentPopup:false,
      ngoAndSupportGroupData:null,
      ngoAndSupportGroupDataResults:[],
      favouriteAppointmentListData:null,
      favouriteAppointmentListResults:[],
      ngoAndSupportGroupTab:true,
      supportGroupInfoData:null,
      supportGroupInfoDataResluts:[],
      healthRecordViewPopupState:false,
      healthRecordViewPopupData:null
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters',
      'recentAppointmentListGetters',
      'healthRecordsGetters',
      'getLbilLinkageToLymphomapInfoGetters',
      'ngoListGetters'
    ]),
    getLymphomapPrflInfoFun(){
      return this.lymphomapPrflInfo = this.$store.getters.getLbilLinkageToLymphomapInfoGetters.data
    },
    setLymphomapPrflInfoStateFun(){
      if(this.lymphomapPrflInfo){
        this.lymphomapPrflInfoData = this.lymphomapPrflInfo.data || null
      }
      return;
    },
    getSelfUserInfoMethod() {
      return this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data
    },
    getRecentAppointmentListMethod() {
      return this.recentAppointmentDetails = this.$store.getters.recentAppointmentListGetters.data
    },
    getHealthRecordListMethod() {
      return this.healthRecordData = this.$store.getters.healthRecordsGetters
    },
    getHealthRecordListResultsMethod(){
      if(this.healthRecordData){
        this.healthRecordDataResults = this.healthRecordData.data
      }
      return;
    },
    getNgoListDataMethod(){
      return this.ngoAndSupportGroupData = this.$store.getters.ngoListGetters
    },
    setInitialStateOfNgoFun(){
      if(this.ngoAndSupportGroupData){
        this.ngoAndSupportGroupDataResults = this.ngoAndSupportGroupData.data
      }
      return;
    },
  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getSelfUserInfoMethod
    this.getRecentAppointmentListMethod
    this.getHealthRecordListMethod
    this.getHealthRecordListResultsMethod
    this.getLymphomapPrflInfoFun
    this.setLymphomapPrflInfoStateFun
    this.getNgoListDataMethod
    this.setInitialStateOfNgoFun
    bus.$on('updateProfilePhotoModalBus', (data) => {
      this.updateProfilePhotoModal = data
    });
    bus.$on('uploadMedicalRecordsModalBus', (data) => {
      this.uploadMedicalRecordsModal = data
    });
    bus.$on('uploadDocumentIDBus', (data) => {
      this.uploadDocumentIDState = data
    });
    bus.$on('addAboutPopupStateBus', (data) => {
      this.addAboutPopupState = data
    });
    bus.$on('blurTextbPopupStateBus', (data) => {
      this.blurTextbPopupState = data
    });
    bus.$on('ngoSignUpFormPopupBus', (data) => {
      this.ngoSignUpFormPopup = data
    });
    bus.$on('ngoBlurbContentPopupBus', (data) => {
      this.ngoBlurbContentPopup = data
    });
    bus.$on('SGSignUpFormPopupBus', (data) => {
      if(!data) return;
      this.$store.dispatch('getSupportGroupListFunc').then((res) => {
        this.supportGroupInfoData = res.data
        this.supportGroupInfoDataResluts = this.supportGroupInfoData.data
      })
    });
    bus.$on('healthRecordViewPopupStateBus', (data) => {
      this.healthRecordViewPopupState = data
    });
  },
  methods: {
    healthRecordViewPopupMethod(data){
      this.healthRecordViewPopupData = data
      this.healthRecordViewPopupState = true
      document.body.classList.add('popup_open_body')
    },
    tabChangeNgoToSupportGroupMethod(){
      if(this.ngoAndSupportGroupTab){
        this.ngoAndSupportGroupTab = false
        if(!this.ngoAndSupportGroupTab){
          this.$store.dispatch('getSupportGroupListFunc').then((res) => {
            this.supportGroupInfoData = res.data
            this.supportGroupInfoDataResluts = this.supportGroupInfoData.data
          })
        }
      }else{
        this.ngoAndSupportGroupTab = true
      }
    },
    ngoSignUpFormPopupMethod(){
      document.body.classList.add('popup_open_body')
      this.ngoBlurbContentPopup =  true
    },
    openAddAboutPopup(){
      document.body.classList.add('popup_open_body')
      this.blurTextbPopupState =  true
    },
    dashboardInfoTabMethod(option){
      this.dashboardInfoTab = option
      if(this.dashboardInfoTab == 'Notifications'){
        this.$store.dispatch('getNotificationFunc')
      }
    },
    dashboardTabChangeMethod(option){
      if(option == 'health_record'){
        this.$store.dispatch('getHelthRecordsListApiFun').then((res) => {
          this.dashboardTab = option
        })
      }else if(option == 'Ngo'){
        this.$store.dispatch('getNGOApiFun').then((res) => {
          this.dashboardTab = option
        })
      }else if(option == 'my_fav_doctors'){
        this.dashboardTab = option
      }else{
        this.dashboardTab = option
      }
    },
    updateProfilePhotoFun(){
      document.body.classList.add('popup_open_body')
      this.updateProfilePhotoModal = true
    },
    healthRecordModal(){
      document.body.classList.add('popup_open_body')
      this.uploadMedicalRecordsModal = true
    },
    getCountryName(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    uploadDocumentIDPoupEnabled(data){
      this.documentIdProofData = data
      document.body.classList.add('popup_open_body')
      this.uploadDocumentIDState = true
    }
  },
  watch : {
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters
    },
    getRecentAppointmentListMethod() {
      return this.$store.getters.recentAppointmentListGetters
    },
    getHealthRecordListMethod() {
      return this.healthRecordData = this.$store.getters.healthRecordsGetters
    },
    getHealthRecordListResultsMethod(){
      if(this.healthRecordData){
        this.healthRecordDataResults = this.healthRecordData.data
      }
      return;
    },
    getLymphomapPrflInfoFun(){
      return this.$store.getters.getLbilLinkageToLymphomapInfoGetters
    },
    setLymphomapPrflInfoStateFun(){
      if(this.lymphomapPrflInfo){
        this.lymphomapPrflInfoData = this.lymphomapPrflInfo.data || null
      }
      return;
    },
    getNgoListDataMethod(){
      return this.$store.getters.ngoListGetters;
    },
    setInitialStateOfNgoFun(){
      if(this.ngoAndSupportGroupData){
        this.ngoAndSupportGroupDataResults = this.ngoAndSupportGroupData.data
      }
      return;
    },
  }
}