/*eslint-disable */
import {bus} from '@/main';  
export default {
  name: 'before-booking-message-list',
  components: {},
  props: ['msgPatientListData'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    messageListOfPatientPopupClosedMethod(){
      bus.$emit('beforeBookingMessageListStateBus', false)
      document.body.classList.remove('popup_open_body')
    },
  }
}


